$xl-width: 1200px;
$lg-width: 992px;
$md-width: 768px;
$sm-width: 576px;

$xl-max-width: 1160px;
$lg-max-width: 882px;
$md-max-width: 774px;
$sm-max-width: 650px;

$dark-color: #000;
$little-dark-color: #222;
$theme-color: #06774a;
$theme-color-lite: #ea337f;

// 2560 x 1600
// 1920 × 1080 (8.89%)
// 1536 × 864 (3.88%)
// 1366 × 768 (8.44%)
// 1024 x 768

// 480 width
// 375 width

//no use
// 414×896 (4.58%)
// 375×667 (3.75%)
// 360×640 (7.28%)

@font-face {
  font-family: "hubolt_sans_bold";
  src: url("../fonts/Hubot-Sans-Bold.woff") format("woff");
}

@font-face {
  font-family: "hubolt_sans_bold_narrow";
  src: url("../fonts/Hubot-Sans-BoldNarrow.woff") format("woff");
}

@font-face {
  font-family: "hubolt_sans_black";
  src: url("../fonts/Hubot-Sans-Black.woff") format("woff");
}

@font-face {
  font-family: "hubolt_sans_black_narrow";
  src: url("../fonts/Hubot-Sans-BlackNarrow.woff") format("woff");
}
@font-face {
  font-family: "Impact";
  src: url("../fonts/new/impact.ttf") format("opentype");
}

// proxima-nova Font family

@font-face {
  font-family: "proxima-nova";
  src: url("../fonts/proxima-nova/proxima_nova_black-webfont.woff")
      format("woff2"),
    url("../fonts/proxima-nova/proxima_nova_black-webfont.woff2") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src: url("../fonts/proxima-nova/proxima_nova_bold-webfont.woff")
      format("woff2"),
    url("../fonts/proxima-nova/proxima_nova_bold-webfont.woff2") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src: url("../fonts/proxima-nova/proxima_nova_bold-webfont.woff")
      format("woff2"),
    url("../fonts/proxima-nova/proxima_nova_bold-webfont.woff2") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src: url("../fonts/proxima-nova/proxima_nova_semibold-webfont.woff")
      format("woff2"),
    url("../fonts/proxima-nova/proxima_nova_semibold-webfont.woff2")
      format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima-nova";
  src: url("../fonts/proxima-nova/proxima_nova_regular-webfont.woff")
      format("woff2"),
    url("../fonts/proxima-nova/proxima_nova_regular-webfont.woff2")
      format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
}

// nunito-sans Font family

@font-face {
  font-family: "nunito-sans";
  src: url("../fonts/nunito-sans/nunitosans-bold-webfont.woff") format("woff2"),
    url("../fonts/nunito-sans/nunitosans-bold-webfont.woff2") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
}

$proxima-nova: "proxima-nova" !important;
$nunito-sans: "nunito-sans" !important; //Bold Heading
$hubolt-sans-bold: "hubolt_sans_bold" !important;
$hubolt-sans-bold-narrow: "hubolt_sans_bold_narrow" !important;
$hubolt-sans-black: "hubolt_sans_black" !important;
$hubolt-sans-black-narrow: "hubolt_sans_black_narrow" !important;
$Impact: "Impact" !important;
@media only screen and (min-width: 2100px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 80%;
  }
}

.theme-yellow-text {
  color: #e6d44d;
}

.theme-pink-text {
  color: #ea337f;
}
